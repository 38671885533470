import HttpService from "./HttpService";

export default class NotificationSettingsService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get Notifications
   *
   * @returns {Promise}
   */
  getSettings() {
    return this.httpService.get("/v1/seller_settings/notifications_settings");
  }

  updateSettings(id = null, data = {}) {
    return this.httpService.put("/v1/seller_settings/" + id, data);
  }

  /**
   * Put SMS
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateSMS(data) {
    return this.httpService.put("/v1/seller_settings/send_sms", data);
  }

  /**
   * Put Whatsapp
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateWhatsApp(data) {
    return this.httpService.put("/v1/seller_settings/send_whatsapp", data);
  }

  /**
   * Put webhook
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateWebHook(data) {
    return this.httpService.put("/v1/sellers/seller_webhook", data);
  }

  /**
   * Put email
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateEmail(data) {
    return this.httpService.put("/v1/seller_settings/notification_email", data);
  }

  resendVerificationEmail() {
    return this.httpService.post(
      "/v1/seller_settings/resend_verification_email"
    );
  }
}
