<template>
  <LoadingPage v-if="loaded" />
  <div v-else class="insurance">
    <div class="content">
      <NbPageTitle
        :title="$t('insurancePace.title')"
        :subTitle="$t('insurancePace.subtitle')"
      />

      <NbCardBox class="card-container">
        <div>
          <h3 class="heading-3 mb-1 mt-0">
            {{ $t("insurancePace.calculationBase") }}
          </h3>
          <p class="body-3 m-0">
            {{ $t("insurancePace.choosHowInsuranceShouldBeCalculated") }}
          </p>

          <div class="nb-radio-group">
            <div class="nb-radio-item">
              <input
                v-model="insuranceOption"
                @change="handleForm"
                type="radio"
                id="useSellingPrice"
                :value="0"
              />
              <label for="useSellingPrice">
                {{ $t("insurancePace.useSellingPrice") }}
              </label>
            </div>

            <div class="nb-radio-item with-input">
              <input
                v-model="insuranceOption"
                @change="handleForm"
                type="radio"
                id="alwaysInsure"
                :value="1"
              />
              <div class="group">
                <label for="alwaysInsure">
                  {{ $t("insurancePace.alwaysInsure") }}
                </label>
                <div class="inputs">
                  <!-- <div :class="['prefix', {active: insuranceOption === 1}]">{{ currencyFormat }}</div> -->

                  <NbTextInput
                    v-model="settings.insurance_cost"
                    id="insurance-cost"
                    class="cost"
                    :customStyle="{ 'border-radius': 0 }"
                    :disabled="insuranceOption !== 1"
                    placeholder="0,00"
                    @blur="handleForm"
                    :prepend="currencyFormat"
                    :decimals="2"
                    type="money"
                  />
                  <NbSelectInput
                    v-model="settings.insurance_currency"
                    id="select-input-example-1"
                    name=""
                    :options="currencyOptions"
                    :customStyle="{ 'border-top-left-radius': 0 }"
                    :disabled="insuranceOption !== 1"
                    @change="updateSellerSettings"
                  />
                </div>
              </div>
            </div>

            <div class="nb-radio-item with-input">
              <input
                v-model="insuranceOption"
                @change="handleForm"
                type="radio"
                id="ofSellingPrice"
                :value="2"
              />
              <div class="group">
                <label for="ofSellingPrice">{{
                  $t("insurancePace.use")
                }}</label>
                <div class="inputs">
                  <NbTextInput
                    v-model="settings.insurance_pct"
                    id="insurance-pct"
                    class="percent"
                    :customStyle="{ 'border-top-right-radius': 0 }"
                    :disabled="insuranceOption !== 2"
                    placeholder="0,00"
                    @blur="handleForm"
                    append="%"
                    :decimals="2"
                    type="money"
                  />
                </div>
                <label for="ofSellingPrice">{{
                  $t("insurancePace.ofSellingPrice")
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 class="heading-3 mb-1 mt-0">
            {{ $t("insurancePace.insuranceValue") }}
          </h3>
          <p class="body-3 m-0">
            {{ $t("insurancePace.insuranceValueCaption") }}
          </p>

          <div class="nb-radio-group">
            <div class="nb-radio-item">
              <input
                v-model="settings.include_freight"
                @change="handleForm"
                type="radio"
                id="yesShipCoveredClain"
                :value="true"
              />
              <label for="yesShipCoveredClain">
                {{ $t("insurancePace.yesShipCoveredClain") }}
              </label>
            </div>

            <div class="nb-radio-item">
              <input
                v-model="settings.include_freight"
                @change="handleForm"
                type="radio"
                id="noCoverItemOnly"
                :value="false"
              />
              <label for="noCoverItemOnly">
                {{ $t("insurancePace.noCoverItemOnly") }}
              </label>
            </div>
          </div>
        </div>
      </NbCardBox>
    </div>

    <footer>
      <p class="m-0">{{ $t("components.footer.needHelp") }}?</p>
      <a
        href="https://nobordistinc.freshdesk.com/support/home"
        target="_blank"
        class="link-1"
      >
        {{ $t("components.footer.learMoreAboutInsurance") }}
      </a>
    </footer>

    <!-- Modal add webhook-->

    <!-- Modal edit email-->

    <!-- Modal confirm sms-->

    <!-- Modal confirm whatsApp-->
  </div>
</template>

<script>
import NotificationSettingsService from "@/services/NotificationSettingsService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import NbCardBox from "../../components/cards/NbCardBox.vue";
import NbTextInput from "../../components/input/text/NbTextInput.vue";
import NbSelectInput from "../../components/input/select/NbSelectInput.vue";

const notificationSettingsService = new NotificationSettingsService();

export default {
  name: "Settings",
  components: {
    LoadingPage,
    NbPageTitle,
    NbCardBox,
    NbTextInput,
    NbSelectInput,
  },
  data: () => {
    return {
      settings: {
        id: null,
        language: null,
        measure_mode: null,
        notification_email: null,
        seller_id: null,
        seller_webhook: null,
        send_sms: null,
        send_whatsapp: null,
        created_at: null,
        updated_at: null,
        insurance_cost: null,
        insurance_pct: null,
        include_freight: null,
        insurance_currency: null,
      },
      sms_loader: false,
      whatsapp_loader: false,
      webhook_loader: false,
      email_loader: false,
      loaded: true,
      webhook: {
        url: null,
        key: null,
      },
      notification_email: null,
      errors: [],
      insuranceOption: null,
      currencyOptions: [
        { value: "USD", text: "USD" },
        { value: "BRL", text: "BRL" },
      ],
      price: "",
      currency: "",
      percent: "",
      include_freight: null,
      currencySymbol: "$",
      saveWorkInterval: setTimeout(() => null),
    };
  },
  computed: {
    currencyFormat: {
      get() {
        const currency = this.settings.insurance_currency;
        if (this.settings.insurance_currency) {
          return Number()
            .toLocaleString("en", {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
            .replace(/\d/g, "")
            .trim();
        }
        return this.currencyOptions[0].value;
      },
      set(value) {
        this.currencySymbol = value;
      },
    },
  },
  beforeMount() {
    notificationSettingsService.getSettings().then((response) => {
      this.settings = response.data.data;
      this.selectInsuranceOption();
      this.loaded = false;
    });
  },
  methods: {
    handleForm() {
      if (
        (this.insuranceOption === 1 && this.settings.insurance_cost === null) ||
        (this.insuranceOption === 2 && this.settings.insurance_pct === null)
      ) {
        return;
      }

      this.updateSellerSettings();
    },
    copyWebhook() {
      this.webhook.url = this.settings.seller_webhook.url;
      this.webhook.key = this.settings.seller_webhook.key;
    },

    copyEmail() {
      this.notification_email = this.settings.notification_email;
    },

    updateSellerSettings() {
      clearInterval(this.saveWorkInterval);

      this.saveWorkInterval = setTimeout(() => {
        this.$root.$emit("isSaving", true);
        notificationSettingsService
          .updateSettings(this.settings.id, this.settings)
          .then((response) => {
            this.settings = response.data.data.elements;
            this.loaded = false;
          })
          .finally(() => {
            this.$root.$emit("isSaving", false);
          });
      }, 500);
    },
    selectInsuranceOption() {
      if (this.settings.insurance_cost) {
        this.insuranceOption = 1;
      } else if (this.settings.insurance_pct) {
        this.insuranceOption = 2;
      } else {
        this.insuranceOption = 0;
      }
    },

    updateSMS() {
      this.sms_loader = true;
      notificationSettingsService
        .updateSMS({ send_sms: !this.settings.send_sms })
        .then(() => {
          notificationSettingsService.getSettings().then((response) => {
            this.settings = response.data.data;
            this.sms_loader = false;
            document.getElementById("confirmSMSClose").click();
            //$('#confirmSMS').modal('hide');
          });
        });
    },
  },
  watch: {
    insuranceOption(value) {
      if (value !== 1) {
        this.settings.insurance_cost = null;
      }

      if (value !== 2) {
        this.settings.insurance_pct = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.notifications-settings-block {
  width: 100%;
  margin: 20px 0;
  padding: 25px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px #707070;
  box-shadow: 0 0 10px #707070;
}

.notifications-settings-block-title {
  font-weight: 600;
}

.notifications-settings-block-title span {
  font-weight: bolder;
  color: #006996;
  font-size: 15px;
}

.notifications-settings-block-content {
  display: flex;
  margin-top: 25px;
}

.notifications-settings-block-content-left-part {
  width: 80%;
}

.notifications-settings-block-content-right-part {
  width: 20%;
  position: relative;
}

.notifications-settings-block-content-right-part button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.card-container {
  max-width: 38.4rem;
}

.card-container > div:first-child {
  border-bottom: 1px solid var(--gray-10);
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.insurance {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (25px + 56px));
  .content {
    flex: 1;
  }
}

.nb-radio-group {
  margin-top: 1rem;
  .nb-radio-item {
    display: flex;
    gap: 0.375rem;

    + .nb-radio-item {
      &:not(.with-input) {
        margin-top: 0.375rem;
      }
    }

    &.with-input {
      align-items: center;
      .group {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        .inputs {
          display: flex;
          .symbol {
            max-width: 42px;
          }

          .percent,
          .cost {
            max-width: 100px;
          }

          & > :not([hidden]) ~ :not([hidden]) {
            position: relative;
            &::before {
              content: "";
              height: 28px;
              top: 6px;
              width: 1px;
              left: 0;
              background-color: var(--gray-10);
              display: inline;
              position: absolute;
              z-index: 1;
              border-radius: 4px;
            }
          }
        }
      }
    }

    label {
      color: var(--gray-60);
      display: flex;
    }

    input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--gray-05);
      margin: 0;
      font: inherit;
      color: var(--gray-60);
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid var(--gray-60);
      border-radius: 50%;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--gray-20);
        border-color: var(--gray-40);
      }

      &:focus {
        outline: none;
        border-color: var(--black);
        &::before {
          border: 1px solid var(--gray-60);
        }
      }

      &::before {
        content: "";
        border: 1px solid transparent;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
      }

      &:checked {
        & + label {
          color: var(--black);
        }

        &::before {
          transform: scale(1);
          background-color: var(--black);
        }
      }
    }
  }
}

footer {
  border-top: 1px solid var(--gray-10);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
